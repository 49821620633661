import { db } from '@/firebase/config'
import getUser from "@/composables/getUser";

const { user } = getUser()

const createProfile = async (data, uid) => {
  try {
    await db.collection("Profiles").doc(uid).set({
      isSenior: data.isSenior,
      about: data.about,
      careerTrack: data.careerTrack,
      major: data.major,
      company: data.company,
      GPA: Number(data.gpa),
      firstName: data.firstName,
      lastName: data.lastName,
      year: data.year,
      experience: data.experience,
      email: data.email
    })

    await db.collection("Profiles").doc(uid).collection("myUnreadMsgs").doc("unreadMsgs").set({
      unreadMsgs: 0,
    })
    
    await user.value.updateProfile({ displayName: data.firstName + " " + data.lastName })

    // await db.collection("Stats").doc("profiles").get().then((doc) => {
    //   if(data.isSenior){
    //     doc.ref.update({
    //       seniorCount: doc.data().seniorCount+1
    //     })
    //   }
    //   else {
    //     doc.ref.update({
    //       juniorCount: doc.data().juniorCount+1
    //     })
    //   }
    // })

  } catch (error) {
    alert(error)
  }

};

export default createProfile

