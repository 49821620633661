import { ref } from 'vue'
import { auth, db } from '../firebase/config'
import uploadFile from "@/composables/Storage/uploadFile";
import createNewChat from "@/composables/Msg/createNewChat";
import createProfile from './Profile/createProfile';

const error = ref(null)

const signUp = async (email, password, data) => {
  error.value = null

  try {
    const res = await auth.createUserWithEmailAndPassword(email, password)
    if (!res) {
      throw new Error('Could not complete signup')
    }
    await createProfile(data, res.user.uid);

    if(!data.isSenior){
      if (data.resume.files[0]) {
        await uploadFile(data.resume.files[0], res.user.uid, "Resumes");
      }
      if (data.profilePic.files[0]) {
        await uploadFile(data.profilePic.files[0], res.user.uid, "Images");
      }
    }
    
    // if(data.isSenior){
    //   await db.collection("Stats").doc("profiles").get().then((doc)=>{
    //     doc.ref.update({
    //       seniorCount: doc.data().seniorCount + 1
    //     })
    //   })
    // }
    // else{
    //   await db.collection("Stats").doc("profiles").get().then((doc)=>{
    //     doc.ref.update({
    //       juniorCount: doc.data().juniorCount + 1
    //     })
    //   })
    // }
    
    // if(data.company!=null && data.company!="default"){
    //   await db.collection("Companies").doc(data.company).get().then((doc)=>{
    //     doc.ref.update({
    //       alumniNum: doc.data().alumniNum + 1
    //     })
    //   })
    // }

    await createNewChat("VNUAESxGvbhu3xspHF3IaEPl1lz2", res.user.uid);

    error.value = null
    return res
  }
  catch (err) {
    alert(err.message)
    // console.log(err.message)
    error.value = err.message
  }
}

const useSignUp = () => {
  return { error, signUp }
}

export default useSignUp
